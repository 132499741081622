import { useEffect, useRef, useState } from "react";
import { axiosPost } from "../lib/api";
import { useNavigate } from "react-router-dom";

// Function to load script and append in DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("Razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("Error loading Razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({
  orderId,
  keyId,
  currency,
  amount,
  data,
  setShow,
  setSMsg,
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const navigate = useNavigate();

  // To load Razorpay checkout modal script.
  const displayRazorpay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // Configuring the Razorpay options object
    const options = {
      key: keyId, // Your Razorpay Key ID
      amount: amount * 100, // Amount in paise (1 INR = 100 paise)
      currency: currency,
      name: "Spectransys",
      description: "Test Transaction",
      image: "/favicon.png", // Your Company Logo
      order_id: orderId, // Unique order ID from your server
      handler: async (response) => {
        // On successful payment
        paymentId.current = response.razorpay_payment_id;
        paymentMethod.current = response.method;

        // Informing server about the payment
        await handlePayment("success", response);
      },
      prefill: {
        name: data.firstName,
        email: data.email,
        contact: data.mobile,
      },
      notes: {
        address: data.address,
      },
      theme: {
        color: "#3399cc",
      },
    };

    // All information is loaded in options
    const rzp1 = new window.Razorpay(options);

    // If you want to retrieve the chosen payment method
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    // To open Razorpay checkout modal
    rzp1.open();
  };

  // Informing server about payment status
  const handlePayment = async (status, orderDetails = {}) => {
    let name = `${data?.firstName} ${data?.lastName}`;
    const jsonData = {
      paymentId: orderDetails.razorpay_payment_id,
      orderId: orderDetails.razorpay_order_id,
      email: data?.email,
      firstName: name,
      // signature: orderDetails.razorpay_signature,
    };
    try {
      await axiosPost
        .post(`event/verify/payment`, jsonData)
        .then((response) => {
          if (response.status === 200) {
            setShow(true);
            setSMsg(status);
            navigate("/");
          }
        })
        .catch((error) => {
          console.error("POST Error:", error);
        });
    } catch (error) {
      console.error("Error while handling payment:", error);
    }
  };

  const [displayLoading, setdisplayLoading] = useState(true);

  useEffect(() => {
    if (displayLoading === true) {
      setdisplayLoading(false);
      displayRazorpay();
    }
  }, []);

  return null;
};

export default RenderRazorpay;
