"use client";

import { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function Toast({ show, setShow, msg, error }) {
  useEffect(() => {
    if (show) {
      // Set up a timer to hide the toast after 5 seconds
      const timer = setTimeout(() => {
        setShow(false);
        // msg('')
      }, 2000);

      // Clean up the timer if the component unmounts or `show` changes
      return () => clearTimeout(timer);
    }
  }, [show, setShow]);

  const bgColor = error ? "bg-[#FFE9E9]" : "bg-[#FFFFF0]";
  const text = error ? "text-[#DF3B3B]" : "text-[#FF6500]";

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed top-10 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            enter="transition ease-out duration-300"
            enterFrom="transform translate-y-2 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform translate-y-2 opacity-0"
          >
            <div
              className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg ${bgColor} shadow-lg ring-1 ring-black ring-opacity-5`}
            >
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex w-0 flex-1 justify-between">
                    <p className={`w-0 flex-1 text-sm font-medium ${text} `}>
                      {msg}
                    </p>
                    {/* <button
                      type="button"
                      className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Undo
                    </button> */}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                      }}
                      className="inline-flex rounded-md bg-transparent text-gray-100 hover:text-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
