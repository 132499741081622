import { axiosGet, axiosPost } from '../lib/api';
import { useEffect, useState } from 'react';
import RenderRazorpay from '../Components/RenderRazorpay';
import Toast from '../Components/Toast';
import { Route, useLocation, useNavigate } from 'react-router-dom';

export default function Pricing() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get('d');

  const [data, setData] = useState([]);
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [eventFee, setEventFee] = useState('')
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const [show, setShow] = useState(false);
  const [sMsg, setSMsg] = useState("");

  // useEffect(() => {
  // const userID = localStorage.getItem('userId');

  const fetchAppAttendee = () => {
    axiosGet
      .get(
        `attendee/id/web`,
        {
          params: {
            dataUniqId: userId,
          }
        }
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchEventData = () => {
    axiosGet
      .get(`eventsection/get/web`)
      .then((response) => {
        let data = response.data.data[0];
        // setEventData(data);
        setEventFee(data?.eventFee)

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (userId) {
      fetchEventData();
      fetchAppAttendee();
    } else {
      navigate('/')
    }
  }, [userId]);

  const handleCreateOrder = () => {

    const json = {
      amount: eventFee * 100,
      currency: 'INR',
      keyId: 'rzp_test_49pMyOx78pcs53',
      KeySecret: 'pjeMjZLNyvjWfqKFIUyUp8st',

    }
    axiosPost.post('event/fee/payment', json)
      .then((res) => {
        let data = res.data
        setOrderDetails({
          orderId: data.id,
          currency: data.currency,
          amount: data.amount,
        });
        setDisplayRazorpay(true);
        setTimeout(() => {
          setDisplayRazorpay(false);
        }, 3000);
      })
  };

  return (
    <div className='h-[79vh] grid place-items-center px-4 md:px-0'>
      {/* <div>
        <div
          className="text-center global-title"
          style={{ fontFamily: "Cooper-Std" }}
        >
          <h2 className="inline-block text-stroke font-extrabold text-xl md:text-3xl text-black">
            Registration for attendee
          </h2>
        </div>
        <div
          className="border-gray-100 rounded-2xl border divide-y divide-gray-200 max-w-xl mx-auto mt-8"
          style={{
            boxShadow: 'rgba(45, 50, 130, 0.15) 0px 12px 16px -4px, rgba(45, 50, 130, 0.15) 0px 4px 6px -2px',
          }}
        >
          <div className="p-6">

            <div className="flex justify-between">
              <div>
               
                <p className="mt-0.5 text-sm text-gray-500">User Name : {data?.firstName || data?.lastName
                  ? `${data.firstName || ''} ${data.lastName || ''}`?.trim()
                  : '---'}</p>
                <p className="mt-0.5 text-sm text-gray-500">Email : {data?.email ? data?.email : '---'}</p>
                <p className="mt-0.5 text-sm text-gray-500">Mobile : {data?.phoneNumber ? data?.phoneNumber : '---'}</p>

              </div>
              <p className="mt-3">
                <span className="text-4xl font-bold tracking-tight text-gray-900">  {eventFee ? eventFee : <span className="text-sm text-gray-500">---</span>}</span>
                <span className="text-base font-medium text-gray-500">/per person</span>
              </p>
            </div>
          </div>
          <div className="px-6 pt-6 pb-8">
            <h3 className="text-sm font-medium text-gray-900">What's included</h3>
            <ul role="list" className="mt-6 grid grid-cols-2 gap-3  list-inside" style={{ paddingLeft: '0' }}>
              {[
                '5 workspaces',
                'Unlimited testimonials',
                '10 collection forms',
                '20 embeddable widgets',
                'White labeled widgets and forms',
                'Custom branding (logo, colors, etc...)',
                '2 members per workspace',
              ].map((item, index) => (
                <li key={index} className="flex space-x-3">
                  <div className="flex justify-center items-center rounded-full bg-green-100 h-5 w-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      className="h-3 w-3 flex-shrink-0 text-green-500"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20.707 5.293a1 1 0 010 1.414l-11 11a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 15.586 19.293 5.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-sm text-gray-500">{item}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className='p-4'>

            <button onClick={handleCreateOrder}
              rel="noopener noreferrer"
              className="flex justify-center w-full mx-auto py-3 mt-1 text-sm font-medium text-white bg-[#FF6500] border hover:border-[#FF6500] rounded-xl active:text-orange-500 hover:bg-transparent hover:text-[#FF6500] focus:outline-none focus:ring-0"
            >
              Get started now
            </button>
          </div>

        </div>
      </div> */}
      <div class="relative py-6 md:mr-8 px-8 rounded-lg bg-gray-100 w-full md:w-6/12 mt-6 md:mb-0">
        <div class="flex justify-between">
          <div class="w-full">
            <div class="text-blue-900 text-2xl font-semibold mb-2">Registration</div>
            <div class="text-dark-2 text-sm leading-tight">Entry pass covers</div>
          </div>
          <div className="text-dark-1 text-4xl leading-none text-right">
            <span className="flex gap-1 items-center">
              <span className="font-sans">&#8377;</span>
              {eventFee ? (
                eventFee
              ) : (
                <span className="text-sm text-gray-500 whitespace-nowrap"></span>
              )}
            </span>
            <span className="ml-1 text-dark-2 text-base block">/Attendee</span>
          </div>
        </div>
        <div class="border-b border-light-2 w-full mt-6 mb-8"></div>
        <ul class="mb-8">
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Attend all Conference Events
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Conference Papers and Case Studies
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Conference Exhibition and Vendor Booths
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Tea/Coffee Breaks
            </span>
          </li>
          <li class="flex items-center gap-1 text-blue-900 text-sm font-medium mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0" viewBox="0 0 24 24">
              <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.676,8.237-6,5.5a1,1,0,0,1-1.383-.03l-3-3a1,1,0,1,1,1.414-1.414l2.323,2.323,5.294-4.853a1,1,0,1,1,1.352,1.474Z" />
            </svg>
            <span>Attend Sponsored Lunch/Reception Sessions
            </span>
          </li>
        </ul>
        <div className='flex'>
          <button onClick={handleCreateOrder}
            rel="noopener noreferrer"
            className="inline-flex h-10 mx-auto animate-shimmer items-center justify-center rounded-md border  bg-[linear-gradient(110deg,#FF6500,45%,#ff6600d4,55%,#ff66009f)] bg-[length:200%_100%] px-6 font-medium text-slate-50 transition-colors"
          // className="flex animate-shimmer justify-center duration-300 w-full mx-auto py-3 mt-1 text-sm font-medium bg-blue-400 text-white border hover:border-blue-400 rounded-xl active:text-orange-500 hover:bg-transparent hover:text-blue-400 focus:outline-none focus:ring-0"
          >
            Get started now
          </button>
        </div>
      </div>
      {
        displayRazorpay &&
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
          keySecret={process.env.REACT_APP_RAZORPAY_KEY_SECRET}
          data={data}
          setShow={setShow}
          setSMsg={setSMsg}
        />
      }
      <Toast msg={sMsg} show={show} setShow={setShow} />
    </div>
  )
}
