"use client";
import React from "react";
import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
} from "@headlessui/react";
import { IoClose } from "react-icons/io5";

export default function ModeratorBio({ open, setOpen, data }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-30"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {/* <h3 className="font-bold text-center text-xl text-black block pb-5">
              Bio Data
            </h3> */}

            <CloseButton
              className={"absolute right-5 top-5 text-lg text-red-600"}
            >
              <IoClose />
            </CloseButton>
            <div
              className="text-center global-title mb-5"
              style={{ fontFamily: "Cooper-Std" }}
            >
              <h2 className="inline-block font-extrabold text-xl text-black cursor-pointor">
                Moderator Data
              </h2>
            </div>
            <div className="flex flex-col items-center gap-2">
              <img
                src={data.photo}
                alt=""
                className="w-24 h-24 rounded-full"
                srcSet=""
              />
              <h3
                className="text-[#FF6500] text-2xl italic font-semibold block capitalize"
                style={{ fontFamily: "Cooper-Std" }}
              >
                {data.firstName} {data.lastName}
              </h3>
              {data.jobTitle || data.organization ? (
                <h6 className="text-sm block text-[#666666] capitalize">
                  {data.jobTitle}, {data.organization}
                </h6>
              ) : null}
              {data.expertiseName ? (
                <h6 className="text-sm block text-[#666666] capitalize">
                  {" "}
                  Expertise in {data.expertiseName}
                </h6>
              ) : null}
              <div className="flex items-center gap-2">
                {data.phoneNumber ? (
                  <h6 className="text-sm block text-[#666666] capitalize">
                    Mobile :
                    <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
                  </h6>
                ) : null}{" "}
                |{" "}
                {data.email ? (
                  <h6 className="text-sm block text-[#666666]">
                    Email :
                    <a href={`mailto:${data.email}`} className="lowercase">
                      {data.email}
                    </a>
                  </h6>
                ) : null}
              </div>
              {data.country ? (
                <h6 className="text-sm block text-[#666666] capitalize">
                  Country : {data.country}
                </h6>
              ) : null}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
