import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

const navigation = [
  { name: "Home", href: "#home" },
  { name: "Speakers", href: "#speakers" },
  { name: "Panelists", href: "#panelist" },
  { name: "Moderators", href: "#moderators" },
  { name: "Agenda", href: "#agenda" },
  // { name: "Location", href: "#location" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(location.hash);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute("id");
            setActiveLink(`#${id}`);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (
      location.pathname === "/terms-and-conditions" ||
      location.pathname === "/privacy-policy "
    ) {
      setActiveLink();
    }

    // Observe each section
    const sections = navigation
      .map((item) => document.querySelector(item.href))
      .filter(Boolean);
    sections.forEach((section) => observer.observe(section));

    // Cleanup function
    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, [location]);

  const handleNavigation = (href) => {
    // Programmatically navigate to root and scroll to the section
    navigate("/", { replace: true });
    const targetElement = document.querySelector(href);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="sticky z-20 w-full top-0 lg:top-0">
      <Disclosure as="nav" className="bg-white relative shadow-sm">
        <div className="mx-auto max-w-7xl px-2 md:px-8">
          <div className="relative flex h-16 items-center justify-between w-full">
            <div className="absolute inset-y-0 right-4 flex items-center sm:hidden">
              {/* Mobile menu button*/}
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-[#FF6500] hover:text-[#FF6500da] focus:outline-none focus:ring-0 focus:ring-inset">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block h-6 w-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden h-6 w-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <div className="flex items-center justify-between w-full">
              <div className="flex flex-shrink-0 items-center px-4 md:px-0">
                <img alt="Logo" src="images/logo.png" className="h-8 w-auto" />
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {navigation.map((item, index) => (
                    <a
                      key={index}
                      href={item.href}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigation(item.href);
                      }}
                      className={classNames(
                        activeLink === item.href
                          ? "bg-gray-100 text-[#FF6500]"
                          : "text-gray-800 hover:bg-[#FF6500] hover:text-white",
                        "rounded-md px-3 py-2 text-sm font-medium"
                      )}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel className="sm:hidden absolute shadow-lg bg-white w-full duration-1000 z-30">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item, index) => (
              <DisclosureButton
                key={index}
                as="a"
                href={item.href}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation(item.href);
                }}
                className={classNames(
                  activeLink === item.href
                    ? "bg-gray-100 text-[#FF6500]"
                    : "text-gray-800 hover:bg-[#FF6500] hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium"
                )}
              >
                {item.name}
              </DisclosureButton>
            ))}
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  );
}
