import React, { useEffect, useState } from "react";
import { IoMdPin } from "react-icons/io";
import { axiosGet } from "../../lib/api";
import AttendeeBio from "../Modals/BioModal/AttendeeBio";
import { capitalizeText } from "../../lib/config";

const Attendees = () => {
  const [visibleCards, setVisibleCards] = useState(9);
  const [visibleExCards, setVisibleExCards] = useState(18);
  const [bioType, setBioType] = useState(2);
  const [attData, setAttData] = useState([]);
  const [exbData, setExbData] = useState([]);
  const [agendaData, setAgendaData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [openBio, setOpenBio] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const handleViewMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 9);
  };

  const handleViewExMore = () => {
    setVisibleExCards((prevVisibleCards) => prevVisibleCards + 9);
  };

  const fetchAppAttendee = async () => {
    axiosGet
      .get(`attendee/get/website`)
      .then((response) => {
        setAttData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchExhibitors = async () => {
    axiosGet
      .get(`exhibitorsection/get/web?activeStatus=1&limit=0`)
      .then((response) => {
        setExbData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchAgendaData = async () => {
    axiosGet
      .get(`agendasection/get/web?sortField=eventDay&sortOrder=ascending`)
      .then((response) => {
        let data = response.data.data;
        setAgendaData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchEventData = async () => {
    axiosGet
      .get(`eventsection/get/web`)
      .then((response) => {
        let data = response.data.data[0];
        setEventData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    fetchAgendaData();
    fetchAppAttendee();
    fetchEventData();
    fetchExhibitors();
  }, []);

  const viewBio = (card, type) => {
    setBioType(type);
    setOpenBio(true);
    setSelectedData(card);
  };

  const cardClass = "block h-auto w-full px-1 md:px-3 p-2 md:p-6 rounded-lg";
  const convertHtmlToText = (text) => {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <section className="py-4 px-8 md:px-0 bg-[#eff7fa]" id="agenda">
        <div
          className="text-center global-title"
          style={{ fontFamily: "Cooper-Std" }}
        >
          <h2 className="inline-block text-stroke font-extrabold text-xl md:text-3xl text-black">
            Featured Attendees
          </h2>
        </div>

        {attData.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-4 mx-auto max-w-7xl px-8">
            {attData?.slice(0, visibleCards).map((card, index) => (
              <div
                onClick={() => viewBio(card, 2)}
                key={index}
                title={`view bio of  ${capitalizeText(
                  card?.firstName
                )} ${capitalizeText(card?.lastName)}`}
                className="relative col-span-1 flex flex-col md:flex-row gap-2 rounded-xl shadow hover:shadow-lg duration-500 p-3 mx-auto border border-white bg-white w-full cursor-pointer"
              >
                <div className="w-full md:w-20 bg-white">
                  <img
                    src={card?.photo}
                    alt=" profile pic"
                    className="rounded-xl h-20 w-20 object-cover"
                  />
                </div>
                <div className="w-full bg-white flex flex-col space-y-2">
                  <div className=" flex justify-start items-start h-full w-full">
                    <div className="flex flex-col">
                      <p className="text-gray-500 font-medium capitalize line-clamp-1">
                        {card?.firstName} {card?.lastName}
                      </p>
                      <p className="text-gray-500 text-sm capitalize line-clamp-1">
                        {card.roleName && `${card.roleName}, `}
                        {card.company}
                      </p>
                      <p className="text-gray-500 text-sm capitalize line-clamp-1">
                        {card?.industryName}
                      </p>
                    </div>
                    {/* <div
                      className="bg-gray-200 w-fit h-fit px-3 py-1 rounded text-xs font-medium text-gray-800 mt-2 cursor-pointer whitespace-nowrap"
                      onClick={() => viewBio(card)}
                    >
                      View bio
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
            {visibleCards < attData?.length && (
              <div className="flex justify-center w-full col-span-1 md:col-span-2 lg:col-span-3">
                <button
                  onClick={handleViewMore}
                  type="button"
                  className="text-white bg-[#FF6500] hover:bg-black focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2 duration-200 w-full md:w-auto"
                >
                  Expand
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center max-w-7xl mx-auto py-6">
            {" "}
            No Attendees currently...
          </div>
        )}
      </section>
      <section className="max-w-7xl mx-auto lg:mx-auto my-6 relative p-8">
        <div className="relative p-8 h-60 md:h-[650px] mb-10 md:mb-24 py-8 px-8 md:px-6 overflow-hidden rounded-3xl">
          {/* Background Image with Blur Effect */}
          <div
            className="absolute inset-0 z-[-1] bg-cover bg-center"
            style={{
              backgroundImage: `url(${
                eventData?.backgroundImage
                  ? eventData.backgroundImage
                  : "/images/agenda_default.jpg"
              })`,
              filter: "blur(3px)",
            }}
          />

          {/* Content */}
          <div className="absolute inset-0 p-5 md:p-10">
            <h3
              className="text-2xl text-white mb-5 cursor-default"
              style={{ fontFamily: "Cooper-Std" }}
            >
              Agenda
            </h3>
            {/* {agendaData.length > 0} */}
            <div className="h-4/5 overflow-auto aScrollBar px-2">
              <ol className="relative border-s border-gray-300 list-none pl-6">
                {agendaData?.map((data, index) => (
                  <li className="mb-10 ms-1" key={index}>
                    <div className="absolute w-3 h-3 bg-[#FF6500] rounded-full -start-1.5 border border-gray-100"></div>
                    <time className="mb-1 text-xs font-semibold leading-none text-[#FF6500] flex items-center gap-1">
                      {data?.eventDay ? data?.eventDay : "Day"}
                      <svg
                        className="rtl:rotate-180 w-2 h-2 text-[#FF6500] mx-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      {data?.startTime}
                    </time>
                    <h3 className="text-md font-semibold text-white/85 capitalize">
                      {data.topic} - ( {data?.refSpeakerName} )
                    </h3>
                    <p className="mb-4 text-sm font-normal text-[#FF6500] dark:text-gray-400">
                      {convertHtmlToText(data.detail)}
                    </p>
                  </li>
                ))}
              </ol>
              {/* 
              {agendaData?.map((data, index) => (
                <div key={index}>
                  <span className={`${timeClass} bg-blue-400 rounded-lg p-1 px-2 w-fit`}> {data.startTime} - {data?.refSpeakerName}</span>
                  <span className={topicClass}> {data.topic}</span>
                  <span className={detailClass}>
                    {convertHtmlToText(data.detail)}
                  </span>
                </div>
              ))}
               */}
            </div>
          </div>
        </div>
        <div className="md:absolute right-0 md:-bottom-40 flex flex-col w-full justify-around gap-4 items-center lg:items-stretch">
          <div className="mx-auto w-full md:w-5/6 bg-white rounded-md p-6 shadow-lg flex flex-col md:flex-row md:h-96">
            <div className=" flex flex-col justify-around w-full md:w-1/4">
              <div className={cardClass}>
                <h3 className="text-2xl font-black flex items-center gap-3">
                  <img
                    src="icons/calendar.png"
                    alt=""
                    srcSet=""
                    className="w-6"
                  />
                  <span
                    className="text-[#FF6500]"
                    style={{ fontFamily: "Cooper-Std" }}
                  >
                    When
                  </span>
                </h3>
                <p className="text-sm line-clamp-4 w-52 capitalize">
                  {eventData.when ? eventData.when : "Will Update soon..."}
                </p>
              </div>
              <div className={cardClass}>
                <h3 className="text-2xl font-black flex items-center gap-3">
                  <IoMdPin />
                  <span
                    className="text-[#FF6500]"
                    style={{ fontFamily: "Cooper-Std" }}
                  >
                    Where
                  </span>
                </h3>
                <p className="text-sm line-clamp-4 w-52 capitalize">
                  {eventData.where ? eventData.where : "Will Update soon..."}
                </p>
              </div>
            </div>
            <div className="w-full md:w-3/4">
              <iframe
                src={
                  eventData.location
                    ? eventData.location
                    : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28129200.79458462!2d61.010447358121404!3d19.688315932838698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e1!3m2!1sen!2sin!4v1723184277664!5m2!1sen!2sin"
                }
                width="100%"
                height="100%"
                style={{ border: 0, borderRadius: 6 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <div className="h-1 md:h-52"></div>
      <div
        className="text-center global-title"
        style={{ fontFamily: "Cooper-Std" }}
      >
        <h2 className="inline-block text-stroke font-extrabold text-xl md:text-3xl text-black">
          Exhibitors & Partners
        </h2>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-5 py-4 mx-auto max-w-7xl px-8">
        {exbData?.slice(0, visibleExCards).map((item, index) => (
          <div
            onClick={() => window.open(item.webUrl, "_blank")}
            key={index}
            title={`${capitalizeText(item?.title)}`}
            className="relative col-span-1 flex justify-center items-center flex-col rounded-xl shadow hover:shadow-lg duration-500 p-1 mx-auto border border-white bg-white w-full cursor-pointer"
          >
            <div className="w-full mx-auto bg-white">
              <img
                src={item?.media}
                alt={item?.title}
                className="rounded-lg h-24 w-full object-cover pb-1"
              />
            </div>
            <div className="w-full bg-white flex flex-col space-y-2">
              <div className="flex flex-col">
                <p className="text-gray-500 font-medium capitalize line-clamp-2 text-center">
                  {item?.title}
                </p>

                <a
                  href={item?.webUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit website"
                >
                  <button className="text-gray-500 text-[10px] w-full capitalize line-clamp-1 flex justify-center items-center p-2 rounded">
                    Website
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="up-right-from-square"
                      className="svg-inline--fa fa-up-right-from-square fa-sm"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style={{
                        marginLeft: "4px",
                        width: "12px",
                        height: "12px",
                      }}
                    >
                      <path
                        fill="currentColor"
                        d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM488 0H352c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.88L370.8 96L169.4 297.4c-12.5 12.5-12.5 32.75 0 45.25C175.6 348.9 183.8 352 192 352s16.38-3.125 22.62-9.375L416 141.3l41.38 41.38c9.156 9.141 22.88 11.84 34.88 6.938C504.2 184.6 512 172.9 512 160V24C512 10.74 501.3 0 488 0z"
                      />
                    </svg>
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}
        {visibleExCards < exbData?.length && (
          <div className="flex justify-center w-full col-span-full">
            <button
              onClick={handleViewExMore}
              type="button"
              className="text-white bg-[#FF6500] hover:bg-black focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2 duration-200 w-full md:w-auto"
            >
              Expand
            </button>
          </div>
        )}
      </div>

      <AttendeeBio
        open={openBio}
        setOpen={setOpenBio}
        type={bioType}
        data={selectedData}
      />
    </>
  );
};

export default Attendees;
